@import "magic";
* {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0 !important;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    &, &:before, &:after {
        box-sizing: border-box;
    }
}
a {
    color: inherit;
    text-decoration: none !important;
    transition: 300ms color, 300ms background;
}
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}
img, svg {
    display: block;
    max-width: 100%;
}
button,
input,
optgroup,
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    font: inherit;
    appearance: none;
    display: block;
    color: inherit;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
    &::-ms-clear {
        display: none;
    }
    @include placeholder {
        opacity: 1;
        font-family: inherit;
    }
}
li {
    display: block;
}
.ellipsis-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
