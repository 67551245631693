/* line 1, ../sass/style.scss */

.game-board.basketball {

    * {
        box-sizing: border-box;
    }

    /* line 5, ../sass/style.scss */

    a {
        text-decoration: none;
    }

    /* line 17, ../sass/style.scss */

    .team-border-bottom {
        position: absolute;
        width: 100%;
        height: 10px;
        background: linear-gradient(#979797, #979797, #000, #000);
        left: 0;
        bottom: -10px;
    }

    /* line 26, ../sass/style.scss */

    .all-teams {
        height: 40px;
        border-top-width: 0px;
        border-left-style: none;
        border-right-style: none;
        border-radius: 5px;
        -webkit-transform: skew(45deg);
        -moz-transform: skew(45deg);
        -o-transform: skew(45deg);
        display: -webkit-flex;
        /* Safari */
        display: flex;
        .team-border {
            position: absolute;
            width: 85%;
            height: 3px;
            background: #fff;
            right: 0;
            top: -6px;
        }
        .teams-name {
            background: linear-gradient(#919191, #4B4B4B, #141414, black);
            color: #fff;
            font-size: 22px;
        }
        .teams-score {
            background: linear-gradient(#fff, #979797);
            color: #545353;
            font-size: 30px;
        }
        div {
            -webkit-flex: 1;
            /* Safari 6.1+ */
            -ms-flex: 1;
            /* IE 10 */
            flex-basis: auto;
            span {
                -webkit-transform: skew(-45deg);
                -moz-transform: skew(-45deg);
                -o-transform: skew(-45deg);
                display: block;
                text-align: center;
                font-family: $f;
                font-weight: bold;
                padding: 4px 25px;
            }
        }
        .black-back {
            background: linear-gradient(#000000, #787878, #141414, black);
            height: 40px;
            position: absolute;
            top: 10px;
            padding-left: 120px;
            border-style: solid;
            border-top-color: #fff;
            border-top-width: 2px;
            display: inline-block;
            p {
                position: relative;
                font-family: Arial;
                /*bottom: 13px;*/
                float: right;
                margin-right: 13px;
                font-size: 20px;
                color: #fff;
                -webkit-transform: skew(-45deg);
                -moz-transform: skew(-45deg);
                -o-transform: skew(-45deg);
                display: block;
            }
            .time {
                position: absolute;
                top: 3px;
                width: 60px;
                left: 20px;
                height: 28px;
                background: green;
                -webkit-transform: skew(-45deg);
                -moz-transform: skew(-45deg);
                -o-transform: skew(-45deg);
                display: block;
            }
        }
    }

    /* line 43, ../sass/style.scss */

    /* line 52, ../sass/style.scss */

    /* line 58, ../sass/style.scss */

    /* line 64, ../sass/style.scss */

    /* line 74, ../sass/style.scss */

    /* line 85, ../sass/style.scss */

    /* line 96, ../sass/style.scss */

    /* line 110, ../sass/style.scss */

    /* line 125, ../sass/style.scss */

    body {
        /*----------------------------------*/
        .all-teams div {
            &:nth-child(2) {
                -webkit-animation-delay: 0.2s;
                /* Chrome, Safari, Opera */
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                -webkit-animation-delay: 0.3s;
                /* Chrome, Safari, Opera */
                animation-delay: 0.3s;
            }
            &:nth-child(4) {
                -webkit-animation-delay: 0.4s;
                /* Chrome, Safari, Opera */
                animation-delay: 0.4s;
            }
            &:nth-child(5) {
                -webkit-animation-delay: 0.5s;
                /* Chrome, Safari, Opera */
                animation-delay: 0.5s;
            }
        }
        .black-back {
            -webkit-animation-delay: 1s;
            /* Chrome, Safari, Opera */
            animation-delay: 1s;
        }
        .team-border {
            -webkit-animation-delay: 0.8s;
            /* Chrome, Safari, Opera */
            animation-delay: 0.8s;
        }
        .team-border-bottom {
            -webkit-animation-delay: 0.1s;
            /* Chrome, Safari, Opera */
            animation-delay: 0.1s;
        }
        .animated {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
        .bounceIn {
            -webkit-animation-name: bounceIn;
            animation-name: bounceIn;
        }
        .all-teams div {
            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                -webkit-animation-name: bounceInDown;
                animation-name: bounceInDown;
            }
        }
        .team-border, .team-border-bottom {
            -webkit-animation-name: bounceInLeft;
            animation-name: bounceInLeft;
        }
        .zoomInLeft {
            -webkit-animation-name: zoomInLeft;
            animation-name: zoomInLeft;
        }
        .black-back {
            -webkit-animation-name: zoomInRight;
            animation-name: zoomInRight;
        }
        &.close2 {
            .all-teams div {
                &:nth-child(2) {
                    -webkit-animation-delay: 0.5s;
                    animation-delay: 0.5s;
                }
                &:nth-child(3) {
                    -webkit-animation-delay: 0.4s;
                    animation-delay: 0.4s;
                }
                &:nth-child(4) {
                    -webkit-animation-delay: 0.3s;
                    animation-delay: 0.3s;
                }
                &:nth-child(5) {
                    -webkit-animation-delay: 0.2s;
                    animation-delay: 0.2s;
                }
            }
            .black-back {
                -webkit-animation-delay: 0.7s;
                animation-delay: 0.7s;
            }
            .team-border {
                -webkit-animation-delay: 0.1s;
                animation-delay: 0.1s;
            }
            .team-border-bottom {
                -webkit-animation-delay: 0.6s;
                animation-delay: 0.6s;
            }
            .team-border, .team-border-bottom {
                -webkit-animation-name: fadeOutLeftBig;
                -moz-animation-name: fadeOutLeftBig;
                -o-animation-name: fadeOutLeftBig;
                animation-name: fadeOutLeftBig;
            }
            .all-teams div {
                &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    -webkit-animation-name: fadeOutUpBig;
                    -moz-animation-name: fadeOutUpBig;
                    -o-animation-name: fadeOutUpBig;
                    animation-name: fadeOutUpBig;
                }
            }
            .black-back {
                -webkit-animation-name: zoomOutRight;
                animation-name: zoomOutRight;
            }
        }
    }

    /* line 126, ../sass/style.scss */

    /* line 134, ../sass/style.scss */

    /* line 142, ../sass/style.scss */

    /* line 150, ../sass/style.scss */

    /* line 158, ../sass/style.scss */

    /* line 166, ../sass/style.scss */

    /* line 173, ../sass/style.scss */

    /* line 180, ../sass/style.scss */

    /* line 187, ../sass/style.scss */

    @-webkit-keyframes bounceInDown {
        from, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
        }

        to {
            -webkit-transform: none;
            transform: none;
        }
    }


    @keyframes bounceInDown {
        from, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
        }

        to {
            -webkit-transform: none;
            transform: none;
        }
    }


    /* line 260, ../sass/style.scss */

    @-webkit-keyframes bounceInLeft {
        from, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
        }

        to {
            -webkit-transform: none;
            transform: none;
        }
    }


    @keyframes bounceInLeft {
        from, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
        }

        to {
            -webkit-transform: none;
            transform: none;
        }
    }


    /* line 336, ../sass/style.scss */

    @-webkit-keyframes bounceInRight {
        from, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            opacity: 0;
            -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
        }

        to {
            -webkit-transform: none;
            transform: none;
        }
    }


    @keyframes bounceInRight {
        from, 60%, 75%, 90%, to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            opacity: 0;
            -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
        }

        60% {
            opacity: 1;
            -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
        }

        to {
            -webkit-transform: none;
            transform: none;
        }
    }


    @-webkit-keyframes rollIn {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }


    @keyframes rollIn {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }


    /* line 440, ../sass/style.scss */

    @-webkit-keyframes zoomInRight {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }


    @keyframes zoomInRight {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }


    /* line 481, ../sass/style.scss */

    @-webkit-keyframes zoomInUp {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }


    @keyframes zoomInUp {
        from {
            opacity: 0;
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        60% {
            opacity: 1;
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
    }


    /* line 524, ../all-teamssass/style.scss */

    /* line 529, ../sass/style.scss */

    /* line 534, ../sass/style.scss */

    /* line 539, ../sass/style.scss */

    /* line 544, ../sass/style.scss */

    /* line 549, ../sass/style.scss */

    /* line 554, ../sass/style.scss */

    @-webkit-keyframes fadeOutLeftBig {
        0% {
            opacity: 1;
            -webkit-transform: translateX(0);
        }

        100% {
            opacity: 0;
            -webkit-transform: translateX(-2000px);
        }
    }


    @-moz-keyframes fadeOutLeftBig {
        0% {
            opacity: 1;
            -moz-transform: translateX(0);
        }

        100% {
            opacity: 0;
            -moz-transform: translateX(-2000px);
        }
    }


    @-o-keyframes fadeOutLeftBig {
        0% {
            opacity: 1;
            -o-transform: translateX(0);
        }

        100% {
            opacity: 0;
            -o-transform: translateX(-2000px);
        }
    }


    @keyframes fadeOutLeftBig {
        0% {
            opacity: 1;
            transform: translateX(0);
        }

        100% {
            opacity: 0;
            transform: translateX(-2000px);
        }
    }


    /* line 607, ../sass/style.scss */

    @-webkit-keyframes fadeOutUpBig {
        0% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }

        100% {
            opacity: 0;
            -webkit-transform: translateY(-2000px);
        }
    }


    @-moz-keyframes fadeOutUpBig {
        0% {
            opacity: 1;
            -moz-transform: translateY(0);
        }

        100% {
            opacity: 0;
            -moz-transform: translateY(-2000px);
        }
    }


    @-o-keyframes fadeOutUpBig {
        0% {
            opacity: 1;
            -o-transform: translateY(0);
        }

        100% {
            opacity: 0;
            -o-transform: translateY(-2000px);
        }
    }


    @keyframes fadeOutUpBig {
        0% {
            opacity: 1;
            transform: translateY(0);
        }

        100% {
            opacity: 0;
            transform: translateY(-2000px);
        }
    }


    /* line 663, ../sass/style.scss */

    @-webkit-keyframes zoomOutRight {
        40% {
            opacity: 1;
            -webkit-transform: scale(0.475) translateX(-42px);
            transform: scale(0.475) translateX(-42px);
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
        }

        100% {
            opacity: 0;
            -webkit-transform: scale(0.1) translateX(2000px);
            transform: scale(0.1) translateX(2000px);
            -webkit-transform-origin: right center;
            transform-origin: right center;
        }
    }


    @keyframes zoomOutRight {
        40% {
            opacity: 1;
            -webkit-transform: scale(0.475) translateX(-42px);
            -ms-transform: scale(0.475) translateX(-42px);
            transform: scale(0.475) translateX(-42px);
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
        }

        100% {
            opacity: 0;
            -webkit-transform: scale(0.1) translateX(2000px);
            -ms-transform: scale(0.1) translateX(2000px);
            transform: scale(0.1) translateX(2000px);
            -webkit-transform-origin: right center;
            -ms-transform-origin: right center;
            transform-origin: right center;
        }
    }


    /* line 712, ../sass/style.scss */

    .stream {
        > div {
            vertical-align: top;
            display: inline-block;
        }
        .team-name .scores {
            display: inline;
        }
    }

    .num {
        width: 35px !important;
        padding: 3px !important;
    }

    .video-box {
        margin-top: 20px;
        display: inline-block;
    }

    .buttom-inline {
        vertical-align: bottom !important;
    }
}
