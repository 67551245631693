/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

.game-board.shabby.tableTennis {
    .stream {
        > div {
            vertical-align: top;
            display: inline-block;
        }
        .team-name .scores {
            display: inline;
        }
    }

    .num {
        width: 35px !important;
        padding: 3px !important;
    }

    .video-box {
        margin-top: 20px;
    }

    .time-col {
        .set {
            line-height: 59px;
        }
    }
}
