@function per($size, $dep: 1440) {
    $per: $size / $dep * 100;
    @return #{$per + '%'};
}
@function rem($size) {
    $remSize: $size / 10;
    @if $size == 1 {
        @return #{$size}px;
    }
    @return #{$remSize}rem;
}
@function -rem($size) {
    $remSize: $size / 10;
    @if $size == 1 {
        @return -#{$size}px;
    }
    @return -#{$remSize}rem;
}
@mixin placeholder {
    $prefixList: '::-webkit-input', ':-moz', '::-moz', ':-ms-input';
    @each $prefix in $prefixList {
        &#{$prefix}-placeholder {
            @content;
            opacity: 1;
        }
    }
}
