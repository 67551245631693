.game-board.shabby {
    display: inline-block;
    height: 66px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    font-family: $f;
    background: -moz-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e4e4e4), color-stop(2%, #e4e4e4), color-stop(99%, #a0a0a0), color-stop(100%, #a0a0a0));
    background: -webkit-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
    background: -o-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
    background: -ms-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
    background: linear-gradient(0deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
    .time-col {
        display: inline-block;
        height: 66px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        font-family: $f;
        background: -moz-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e4e4e4), color-stop(2%, #e4e4e4), color-stop(99%, #a0a0a0), color-stop(100%, #a0a0a0));
        background: -webkit-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
        background: -o-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
        background: -ms-linear-gradient(90deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
        background: linear-gradient(0deg, #a0a0a0 0%, #a0a0a0 1%, #e4e4e4 98%, #e4e4e4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e4e4e4', endColorstr='#a0a0a0', GradientType=0);
        width: 59px;
        height: 59px;
        border-radius: 100%;
        background: #2c2c2c;
        display: inline-block;
        margin: 4px;
        color: #fcc350;
        text-align: center;
        overflow: hidden;
        vertical-align: top;
        &.set-col {
            .set {
                display: block;
                font-size: 15px;
                line-height: 35px;
                background: #202020;
            }
            .time {
                line-height: 10px;
            }
        }
        .time {
            display: block;
            font-size: 19px;
            line-height: 59px;
        }
    }
    .name-col {
        font-size: 20px;
        color: #5C5656;
        line-height: 33px;
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        div {
            padding: 0 15px;
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 0px;
                    height: 1px;
                    width: 100%;
                    background: #aeaeae;
                }
            }
            &.serv {
                border-right: 3px solid #fcc350;
            }
        }
    }
    .score-col {
        display: inline-block;
        vertical-align: top;
        margin-left: -5px;
        table {
            border-left: 1px solid #8a8a8a;
            tr {
                td {
                    background: #2c2c2c;
                    line-height: 33px;
                    width: 40px;
                    text-align: center;
                    font-size: 20px;
                    color: #ffcb5c;
                    box-sizing: border-box;
                    &:nth-child(1) {
                        color: #cecece;
                        border-right: 2px solid #303030;
                    }

                    &.space {
                        width: 5px;
                        border-bottom: 0px !important;
                        background: none;
                    }
                }
                &:nth-child(1) td {
                    border-bottom: 2px solid #303030;
                }
            }
        }
    }
}
