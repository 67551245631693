/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

.game-board.classic.volleyball {
    .time-col {
        .set {
            line-height: 59px;
        }
    }
}
