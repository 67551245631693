@import "magic";
@import "reset";
:root {
    --font-family: 'Roboto', sans-serif;
}
html {
    font: 10px / 1.5 $f;
    -webkit-text-size-adjust: 100%;
    background: #4B9427;
    user-select: none;
    @at-root {
        &, body {
            width: 100%;
            height: 100%;
            max-width: 100vw;
        }
    }
    &.production-mode {
        &, body {
            overflow: hidden;
        }
    }
}
body {
    font-size: rem(20);
}
#root {
    width: rem(1920);
    height: rem(1080);
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: rem(64) rem(82);
    position: relative;
    overscroll-behavior: none none;
    .responsive-mode & {
        width: 100vw;
        height: 100vh;
    }
}
.blinker {
    animation: blinkingText 1s linear infinite;
}
@keyframes blinkingText {
    100% {
        color: #000;
    }
}
@import './score-boards/classic.scss';
@import './score-boards/shabby.scss';
@import './score-boards/tableTennis.scss';
@import './score-boards/volleyball.scss';
@import './score-boards/soccer.scss';
@import './score-boards/basketball.scss';
@import './score-boards/badminton.scss';
